import React, { useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import axios from 'axios'
import { setJwt, setUser } from "../redux/app"
import { useDispatch } from "react-redux"
import { NotificationManager } from 'react-notifications'

export default () => {
	const [creds, setCreds] = useState({
		email: '',
		password: ''
	})

	const dispatch = useDispatch()

	const authorize = (event) => {
		event.preventDefault()

		const instanceAxios = axios.create()
		instanceAxios.interceptors.request.use(
			(response) => {
				return response
			},
			(error) => {
				return Promise.reject(error)
			}
		)


		instanceAxios
			.post(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/auth", {
				email: creds.email,
				password: creds.password,
			})
			.then((res) => {
				dispatch(setJwt(res.data.token))
				localStorage.setItem('jwt', res.data.token)
				
				NotificationManager.success(
					"Logowanie poprawne",
					"Autoryzacja",
					3000
				)
			})
			.catch((res) => {
				setCreds(pv => ({...pv, password: '' }) )

				if(res?.response?.status == 401)
				{
					NotificationManager.error(
						"Błędna nazwa użytkownika lub hasło",
						"Błąd autoryzacji",
						3000
					)
				}
				else
				{
					NotificationManager.error(
						"Błąd systemu, spróbuj ponownie później",
						"Błąd",
						3000
					)
				}
			})
	}

	
	return (
		<Container>
			<Row className="justify-content-md-center">
				<Col xs={12} xl={5} className="text-center mt-5">
					<h1>Autoryzacja</h1>
					<Form onSubmit={authorize}>
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label>Adres e-mail</Form.Label>
							<Form.Control
								type="text"
								placeholder="adres e-mail"
								onChange={(e) =>
									setCreds((pv) => ({
										...pv,
										email: e.target.value,
									}))
								}
								value={creds?.email}
							/>
						</Form.Group>

						<Form.Group
							className="mb-3"
							controlId="formBasicPassword"
						>
							<Form.Label>Hasło</Form.Label>
							<Form.Control
								type="password"
								placeholder="hasło"
								onChange={(e) =>
									setCreds((pv) => ({
										...pv,
										password: e.target.value,
									}))
								}
								value={creds.password}
							/>
						</Form.Group>
						<Button variant="primary" type="submit">
							Autoryzacja
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

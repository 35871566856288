import { faCheckCircle, faClock, faExclamationCircle, faPlayCircle, faSpinner, faStopCircle, faSync, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { Button, Table } from "react-bootstrap"
import { NotificationManager } from "react-notifications"


export const Tasks = ({ deviceId, status }) => {
	const [tasks, setTasks] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const reload = () => {
		setIsLoading(true)
		axios.get('/tasks', {
			params: {
				device: deviceId,
				status: status || null
			}
		})
			.then(res => setTasks(res.data))
			.finally(() => setIsLoading(false))
	}

	useEffect(() => {
		reload()
		const interval = setInterval(reload, 10000)

		return () => clearInterval(interval)
	}, [deviceId])



	const Task = ({ task }) => {

		const cancelTask = () => {
			axios
				.patch('/tasks/' + task.id, { 'status': 'CANCELING' })
				.then(() => {
					reload()
					console.log('a')
					NotificationManager.success(
						"Zadanie zostało anulowane",
						"Zadanie",
						3000
					)
				})
		}


		const StatusField = ({ status }) => {
			const canBeCancel = (['PENDING', 'IN_PROGRESS', 'PERIODICALLY'].indexOf(status) != -1)

			return <span>
				{
					status == 'PENDING' && <FontAwesomeIcon icon={faSpinner} spin className="text-secondary" title="pending" /> ||
					status == 'FINISHED' && <FontAwesomeIcon icon={faCheckCircle} className="text-success" title="success" /> ||
					status == 'FAILED' && <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" title="failed" /> ||
					status == 'INVALID' && <FontAwesomeIcon icon={faExclamationCircle} className="text-danger" title="invalid" /> ||
					status == 'CANCELING' && <FontAwesomeIcon icon={faTimesCircle} className="text-warning" title="canceling" /> ||
					status == 'CANCELED' && <FontAwesomeIcon icon={faTimesCircle} className="text-secondary" title="canceled" /> ||
					status == 'IN_PROGRESS' && <FontAwesomeIcon icon={faPlayCircle} className="text-primary" title="in progress" /> ||
					status == 'PERIODICALLY' && <FontAwesomeIcon icon={faClock} className="text-primary" title="cykliczne zadanie" /> ||

					<>{status}</>
				}
				{canBeCancel && <Button size="sm" onClick={cancelTask} variant="danger" className="mx-2"><FontAwesomeIcon icon={faStopCircle} className="text-white" title="stop" /></Button>}
			</span>
		}



		return <tr>
			<td className="text-nowrap" style={{ maxWidth: "170px" }}>{task.id}</td>
			<td className="text-nowrap" style={{ maxWidth: "120px" }}>{task.createdAt}</td>
			<td className="text-nowrap"><StatusField status={task.status} /></td>
			<td>{task.name}</td>
		</tr>
	}

	return <>
		<strong>Zadania
			<Button size="sm" variant="secondary" onClick={reload} disabled={isLoading} className="mx-3">
				<FontAwesomeIcon icon={faSync} spin={isLoading} />
			</Button>


		</strong>
		<Table size="sm" responsive>
			<thead>
				<th>ID</th>
				<th>Data</th>
				<th>Status</th>
				<th>Zadanie</th>
			</thead>
			<tbody>
				{tasks.map(row => <Task task={row} />)}

			</tbody>
		</Table>
	</>
}


import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Container, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { set } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { refresh } from '../redux/app'
import { NotificationManager } from 'react-notifications'




const UserName = ({ iri }) => {
	const [userName, setUserName] = useState('')

	useEffect(() => {
		const fetchUserName = async () => {
			try {
				const response = await axios.get(iri.replace('/api', ''))
				setUserName(response.data.name)
			} catch (error) {
				console.error(error)
			}
		}

		fetchUserName()
	}, [iri])

	return (
		<span>{userName}</span>
	)
}


const AvailabilityTable = () => {
	const [availabilities, setAvailabilities] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const store = useSelector((store) => store.app)
	const dispatch = useDispatch()

	const fetchAvailabilities = async () => {
		setIsLoading(true)
		try {
			let filters = { exists: {} }
			if (searchParams.get('only_unresolved'))
				filters.exists.toDatetime = false

			if (searchParams.get('only_unacknowledge'))
				filters.exists.acknowledgeAt = false

			const response = await axios.get('/availabilities', {
				params: { ...filters, status: "OFFLINE" }
			})
			const availabilitiesWithDeviceDescriptions = await Promise.all(
				response.data.map(async (availability) => {
					const deviceResponse = await axios.get(`${availability.device.replace("/api", "")}`)
					return { ...availability, device: deviceResponse.data }
				})
			)
			setAvailabilities(availabilitiesWithDeviceDescriptions)
		} catch (error) {
			NotificationManager.error("Nie udało się pobrać danych", "Błąd", 3000)
		}
		setIsLoading(false)
	}

	useEffect(() => {
		fetchAvailabilities()
	}, [searchParams])


	const handleChangeOnlyUnresolved = (event) => {
		searchParams.delete('only_unresolved')
		if (event.target.checked)
			searchParams.set('only_unresolved', 'true')

		setSearchParams(searchParams)
	}
	const handleChangeOnlyUnacknowledge = (event) => {
		searchParams.delete('only_unacknowledge')
		if (event.target.checked)
			searchParams.set('only_unacknowledge', 'true')

		setSearchParams(searchParams)
	}





	const [showModal, setShowModal] = useState(false)
	const [selectedAvailability, setSelectedAvailability] = useState(null)
	const [acknowledgeDescription, setAcknowledgeDescription] = useState('')

	const handleOpenModal = (availability) => {
		setSelectedAvailability(availability)
		setShowModal(true)
		setAcknowledgeDescription(null)
	}

	const handleCloseModal = () => {
		setShowModal(false)
	}

	const handleAcknowledgeDescriptionChange = (event) => {
		setAcknowledgeDescription(event.target.value)
	}

	const handleFormSubmit = async (event) => {
		event.preventDefault()
		try {
			await axios.patch(`/availabilities/${selectedAvailability.id}`, {
				acknowledgeDescription: acknowledgeDescription,
			})
			handleCloseModal()
			fetchAvailabilities() // Refresh the availability list
			dispatch(refresh())
		} catch (error) {
			console.error(error)
		}
	}



	return (
		<Container>
			<h1>Awarie
				<Button variant="secondary" onClick={fetchAvailabilities} disabled={isLoading} className="mx-3">
					<FontAwesomeIcon icon={faSync} spin={isLoading} />
				</Button>
			</h1>

			<Form.Check
				type="checkbox"
				label="Pokaż tylko niezakończone"
				checked={searchParams.get('only_unresolved')}
				onChange={handleChangeOnlyUnresolved}
			/>
			<Form.Check
				type="checkbox"
				label="Pokaż tylko niepotwierdzone"
				checked={searchParams.get('only_unacknowledge')}
				onChange={handleChangeOnlyUnacknowledge}
			/>


			<Table striped bordered>
				<thead>
					<tr>
						<td>Urządzenie</td>
						<th>Początek awarii</th>
						<th>Koniec awarii</th>
						<th>Awaria znana</th>
						<th>Status</th>
						{store?.user?.roles.includes('ROLE_AVAILABILITY_ACKNOWLEDGE') &&
							<th></th>
						}
					</tr>
				</thead>
				<tbody>
					{availabilities.map((availability) => (
						<tr key={availability.id}>
							<td>{availability.device.description}</td>
							<td>{moment(availability.fromDatetime).format('YYYY-MM-DD HH:mm')}</td>
							<td>{availability.toDatetime ? moment(availability.toDatetime).format('YYYY-MM-DD HH:mm') : ''}</td>
							<td>{availability.acknowledgeDescription &&
								<>
									{availability.acknowledgeDescription}<br />
									przez <UserName iri={availability.acknowledgeByUser} /><br />
									dnia {moment(availability.acknowledgeAt).format('YYYY-MM-DD HH:mm')}
								</>
							}</td>
							<td>{availability.status}</td>
							{store?.user?.roles.includes('ROLE_AVAILABILITY_ACKNOWLEDGE') &&
								<td>
									<Button onClick={() => handleOpenModal(availability)}>potwierdź awarię</Button>
								</td>
							}
						</tr>
					))}
				</tbody>
			</Table>




			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Potwierdź awarię</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleFormSubmit}>
						<Form.Group className="my-2">
							<Form.Label>Komentarz do awarii</Form.Label>
							<Form.Control
								type="text"
								value={acknowledgeDescription}
								onChange={handleAcknowledgeDescriptionChange}
								required
							/>
						</Form.Group>
						<Button type="submit">Potwierdź awarię</Button>
					</Form>
				</Modal.Body>
			</Modal>
		</Container>
	)
}


export default AvailabilityTable
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAvailabilityCount, setAvailabilityUnackCount, setUser } from '../redux/app'
import axios from 'axios'

const useRefresh = () => {
	const dispatch = useDispatch()
	const store = useSelector((store) => store.app)

	const fetchAvailabilityCount = () => {
		axios.get('/availabilities', {
			params: {
				status: 'OFFLINE',
				exists: {
					toDatetime: false
				}
			}
		})
			.then(res => {
				dispatch(setAvailabilityCount(res.data.length))
			})
			.catch(error => {
				console.log(error)
			})
	}

	const fetchAvailabilityUnackCount = () => {
		axios.get('/availabilities', {
			params: {
				status: 'OFFLINE',
				exists: {
					acknowledgeAt: false,
					toDatetime: false
				}

			}
		})
			.then(res => {
				dispatch(setAvailabilityUnackCount(res.data.length))
			})
			.catch(error => {
				console.log(error)
			})
	}


	const fetchUser = () => {
		console.log('fetch user')
		if (store?.jwt) {
			axios.get('/users/me')
				.then(res => {
					dispatch(setUser(res.data))
				})
		}
	}



	useEffect(() => {
		if (store?.user?.roles.includes('ROLE_AVAILABILITY')) {
			fetchAvailabilityCount()
			fetchAvailabilityUnackCount()
		}
	}, [store?.refresh])



	useEffect(() => {
		console.log('useRefresh', store)

		if (store?.jwt != null)
			axios.defaults.headers.common["Authorization"] = "Bearer " + store?.jwt
		else
			axios.defaults.headers.common["Authorization"] = null
		fetchUser()
	}, [store?.jwt])

}

export default useRefresh

import React, { useEffect, useState } from "react"
import { Container, Row, Col, Table, Button, Form, Stack, Tab, Tabs } from "react-bootstrap"
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faCheckCircle, faExclamationCircle, faPlayCircle, faPlug, faPlugCircleExclamation, faSpinner, faTimesCircle, faStopCircle, faEdit, faSave, faCheck, faPencil, faDog, faSync } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { Tasks } from './Tasks'
import { NotificationManager } from "react-notifications"
import { List as PropertiesList } from '../../Common/Properties/List'
import store from "../../redux/store"
import { useSelector } from "react-redux"

const _ = require('lodash')

const Description = ({ d, setDevice }) => {
	const [isEditing, setIsEditing] = useState(false)
	const [description, setDescription] = useState(d.description)

	const onStartEdit = () => {
		setIsEditing(pv => !pv)
	}

	const onSave = () => {
		axios.patch('/devices/' + d.id, { description: description })
			.then(res => {
				setDevice(res.data.id, res.data)
				setIsEditing(false)
			})
	}

	const onCancel = () => {
		setDescription(d.description)
		setIsEditing(false)
	}

	return <>
		{isEditing && <>
			<Row>
				<Col xs="auto py-0 px-1"><Form.Control type="text" value={description} onChange={e => setDescription(e.target.value)} style={{ maxWidth: '200px' }} /></Col>
				<Col xs="auto py-0 px-1 my-auto"><Button variant="success" size="sm" onClick={onSave}><FontAwesomeIcon icon={faCheck} /></Button></Col>
				<Col xs="auto py-0 px-1 my-auto"><Button variant="danger" size="sm" onClick={onCancel}><FontAwesomeIcon icon={faTimesCircle} /></Button></Col>
			</Row>
		</>
			|| <>{d.description} <Button size="sm" variant="light" onClick={onStartEdit} ><FontAwesomeIcon icon={faPencil} /></Button></>}

	</>
}


const DevicesList = () => {
	const [devices, setDevices] = useState([])
	const [collapedDevices, setCollapsedDevices] = useState([])
	const [selected, setSelected] = useState([])
	const [executingTask, setExecutingTask] = useState(false)
	const [taskToExecute, setTaskToExecute] = useState(null)
	const [devicesProperties, setDevicesProperties] = useState({})
	const [filter, setFilter] = useState('')
	const [advancedMode, setAdvancedMode] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const store = useSelector((store) => store.app)

	const setDevice = (id, newData) => {
		setDevices(pv => {
			let _id = _.findIndex(pv, { id: id })
			let nv = [...pv]
			nv[_id] = newData

			return nv
		})
	}


	const reload = () => {
		setIsLoading(true)

		let promises = []
		promises.push(
			axios.get('/devices')
				.then(res => setDevices(res.data))
				.catch(() => NotificationManager.error('Nie udało się pobrać danych', 'Błąd', 3000))
		)
		promises.push(
			axios.get('/properties')
				.then(res => {
					let nv = {}
					for (let i = 0; i < res.data.length; i++) {
						if (!res.data[i]?.device)
							continue

						let deviceId = res.data[i].device.replace('/api/devices/', '')
						nv[deviceId] = nv?.[deviceId] ?? {}

						nv[deviceId][res.data[i].name] = res.data[i]


					}
					setDevicesProperties(nv)
				})
		)

		Promise.all(promises).finally(() => setIsLoading(false))
	}


	let interval = null

	useEffect(() => {


		reload()
		interval = setInterval(() => reload(), 10000)


		return () => {
			if (interval)
				clearInterval(interval)
		}

	}, [])

	const toggleCollapse = id => {
		setCollapsedDevices(pv => { let nv = [...pv]; nv.indexOf(id) == -1 ? nv.push(id) : _.remove(nv, e => e == id); return nv })
	}


	const DeviceRow = d => {

		const Screenshot = () => {
			return <div style={{ minHeight: "100px" }}>{devicesProperties?.[d.id]?._SCREENSHOT && <img src={'data:image/png;base64,' + devicesProperties?.[d.id]?.['_SCREENSHOT'].value} style={{ height: '100px' }} />}</div>
		}

		const ipAddresses = JSON.parse(devicesProperties?.[d.id]?._IP_ADDRESSES?.value || '[]')
		const macAddresses = JSON.parse(devicesProperties?.[d.id]?._MAC_ADDRESSES?.value || '[]')
		

		return <React.Fragment key={d.id}>
			<tr>
				{store?.user?.roles.includes('ROLE_DEVICES_ACTIONS') &&
					<td style={{ width: "1px" }}>
						<Form.Check
							type="checkbox"
							inline
							checked={_.includes(selected, d.id)}
							onChange={() => setSelected(pv => _.xor(pv, [d.id]))}
						/></td>
				}
				<td style={{ width: "1px" }}>
					<Button size="sm" variant="outline" onClick={() => toggleCollapse(d.id)}><FontAwesomeIcon icon={faAngleDown} /></Button>
				</td>
				<td style={{ width: "1px" }}>
					<Screenshot />
				</td>
				<td style={{ width: "1px" }}>
					<Button size="sm" variant={d.isOnline ? "success" : "danger"} title={d.last_contact_at || 'brak'} style={{ width: "30px", marginRight: "5px" }}><FontAwesomeIcon icon={d.isOnline ? faPlug : faPlugCircleExclamation} /></Button>
				</td>
				<td>
					<div className="float-end">
						{devicesProperties?.[d.id]?.WATCHDOG_ENABLED?.value && <>
							{devicesProperties?.[d.id]?._WATCHDOG_FEEDED?.value && <>
								{<FontAwesomeIcon icon={faDog} className="text-success" /> || <FontAwesomeIcon icon={faDog} className="text-danger" />}<br />
							</> || <>
									<FontAwesomeIcon icon={faDog} className="text-secondary" />
								</>
							}
						</>}
					</div>

					<Description d={d} setDevice={setDevice} /><br />
					{d.isOnline || <strong className="text-danger my-3">nieaktywne od: {d?.last_contact_at || 'b/d'}</strong>}<br />
					{(advancedMode || collapedDevices.indexOf(d.id) != -1) && <>
						ID: {d.id}<br />
						Wersja: {devicesProperties?.[d.id]?._DOCKER_CONTAINERS_VERSION?.value || <>b/d</>}<br />
						Uptime od: {devicesProperties?.[d.id]?._UPTIME_SINCE?.value || <>b/d</>}<br />
						Temperatura procesora: {devicesProperties?.[d.id]?._CPU_TEMP?.value > 0 && <>{devicesProperties?.[d.id]?._CPU_TEMP?.value} &#8451;</> || <>b/d</>}<br />
						Obciążenie procesora: {devicesProperties?.[d.id]?._CPU_LOAD?.value || <>b/d</>}<br />
						Wolna przestrzeń: {devicesProperties?.[d.id]?._TOTAL_SPACE?.value > 0 &&
							<>{Math.round(devicesProperties?.[d.id]?._FREE_SPACE?.value / devicesProperties?.[d.id]?._TOTAL_SPACE?.value * 10000) / 100}%</>
							|| <>b/d</>}<br />
						Jakość WiFi: {devicesProperties?.[d.id]?._WLAN_QUALITY?.value || <>b/d</>} (siła: {devicesProperties?.[d.id]?._WLAN_SIGNAL_LEVEL?.value || <>b/d</>})<br />
						Interfejsy: <br />
						<ul>
							{Object.keys(ipAddresses).map(ifname => <li>{ifname} ({macAddresses[ifname]}): {ipAddresses[ifname].map(ip => <>{ip}; </>)}<br /></li>)}
						</ul>
					</>}
				</td>
			</tr>
			{collapedDevices.indexOf(d.id) != -1 &&
				<tr>
					<td colSpan="3"></td>
					<td colSpan="2">
						<Tabs
							defaultActiveKey="tasks"
							className="mb-3"
						>
							<Tab eventKey="tasks" title="Zadania">
								<Tasks deviceId={d.id} />
							</Tab>
							<Tab eventKey="properties" title="Ustawienia">
								<PropertiesList deviceId={d.id} allowNew={true} />
							</Tab>
						</Tabs>
					</td>
				</tr>
			}

		</React.Fragment>
	}

	const executeTask = (task) => {
		setExecutingTask(true)

		let promises = []
		for (let i = 0; i < selected.length; i++) {
			promises.push(axios.post('/tasks', JSON.stringify({
				'device': '/api/devices/' + selected[i],
				'name': task,
			})
			))
		}

		Promise.all(promises).then(() => {
			setExecutingTask(false)
		})
			.then(() => {
				NotificationManager.success(
					"Zadanie zakolejkowane do wykonania",
					"Zadanie",
					3000
				)
			})

	}



	return (
		<Container>
			<Row>
				<Col>
					<h1>Urządzenia
						<Button variant="secondary" onClick={reload} disabled={isLoading} className="mx-3">
							<FontAwesomeIcon icon={faSync} spin={isLoading} />
						</Button>
					</h1>


					<Stack direction="horizontal" gap={3}>
						{store?.user?.roles.includes('ROLE_DEVICES_ACTIONS') && <>
							<div>
								<Form.Select value={taskToExecute} onChange={e => setTaskToExecute(e.target.value)}>
									<option>- wybierz operację -</option>
									<option value="REBOOT">resetuj</option>
									<option value="UPDATE_DOCKER">aktualizuj Docker</option>
									<option value="RELOAD_PROPERTIES">aktualizuj ustawienia</option>
									<option value="REFRESH_PAGE">odśwież stronę kiosku</option>
								</Form.Select>
							</div>
							<div>
								<Button disabled={selected.length == 0} onClick={() => executeTask(taskToExecute)}>wykonaj ({selected.length}) {executingTask && <FontAwesomeIcon icon={faSpinner} spin />} </Button>
							</div>
						</>}
						<div>
							<Form.Control type="text" placeholder="filtruj" value={filter} onChange={e => setFilter(e.target.value)} style={{ maxWidth: '200px' }} />
						</div>
						<div className="ms-auto">
							<Button variant={advancedMode ? 'success' : 'danger'} onClick={() => setAdvancedMode(pv => !pv)}>widok {advancedMode ? 'podstawowy' : 'zaawansowany'}</Button>
						</div>
					</Stack>

					<Table className="table-responsive">
						<thead>
							<tr>
								{store?.user?.roles.includes('ROLE_DEVICES_ACTIONS') && <th></th>}
								<th></th>
								<th></th>
								<th></th>
								<th>Nazwa urządzenia</th>
							</tr>
						</thead>
						<tbody>
							{devices.filter(v => v.description.toLowerCase().includes(filter.toLowerCase())).map(row => DeviceRow(row))}
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	)
}

export default DevicesList

import axios from "axios"
import React, { useEffect, useState } from "react"
import Form from 'react-bootstrap/Form'
import _ from "lodash"
import { Edit as PropertyEdit } from "./Edit"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync } from "@fortawesome/free-solid-svg-icons"



export const List = ({ deviceId, allowNew }) => {
	const [properties, setProperties] = useState([])
	const [isLoading, setIsLoading] = useState(false)


	const reload = () => {
		setIsLoading(true)
		axios.get('/properties' + (deviceId ? '?device=' + deviceId : '?exists[device]=false'))
			.then(res => setProperties(res.data))
			.finally(() => setIsLoading(false))
	}

	useEffect(() => {
		console.log('load properties')
		reload()
	}, [])



	const handleAddNew = () => {
		setProperties(pv => {
			let nv = [...pv]
			nv.push({
				name: '',
				value: '',
				device: '/api/devices/' + deviceId,
				isNew: true,
				id: Math.floor(Math.random()*1000000)
			})

			return nv
		})
	}


	const handleRemoveNew = (id) => {
		setProperties(pv => {
			let nv = [...pv]
			nv = nv.filter(e => e.id != id)
			return nv
		})
	}



	return <div>
		{allowNew &&
			<strong>Ustawienia
				<Button size="sm" variant="secondary" onClick={reload} disabled={isLoading} className="mx-2">
					<FontAwesomeIcon icon={faSync} spin={isLoading} />
				</Button>
				<Button variant="success" size="sm" className="mx-2" onClick={handleAddNew}>+ dodaj</Button>
			</strong>
		}		
		{properties.filter(e => e.name.substring(0, 1) != '_').map(property => 
			<PropertyEdit deviceId={deviceId} name={property.name} allowDelete={true} reload={reload} isNew={property?.isNew || false} removeNew={() => handleRemoveNew(property.id)} />
			)}
	</div>
}


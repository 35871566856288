import { faCheck, faEdit, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios";
import { set } from "lodash"
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Stack } from "react-bootstrap"
import Form from 'react-bootstrap/Form'



export const Edit = ({deviceId, name, label, type, allowDelete, isNew, reload, removeNew}) => {
	const [value, setValue] = useState(null)
	const [property, setProperty] = useState(null)
	const [newName, setNewName] = useState(name)


	useEffect(() => {
		if(isNew)
			return

		axios.get('/properties/' + ( deviceId || 'GLOBAL' ) + '/' + name)
			.then(res => {
				setValue(res.data.value)
				setProperty(res.data)
			})
	}, [deviceId, name])

	// wyświetl formularz z label i form.control w jednej linii
	const onSave = (value) => {
		if(isNew)
			return axios.patch('/properties/' + deviceId + '/' + newName, {value: value}).then(res => {
				setNewName('')
				setValue(res.data.value)
				setProperty(res.data)
				console.log(res.data)
				reload()
			})

		axios.patch('/properties/' + deviceId + '/' + name , {value: value})
			.then(res => {
				setValue(res.data.value)
				setProperty(res.data)
			})
	}

	const onDelete = () => {
		axios.delete('/properties/' + deviceId + '/' + name)
		.finally(() => reload())
	}
	const onCancel = () => {
		if(isNew)
			removeNew()

		setValue(property?.value || null)
	}

	return <>
		<Form.Group className="my-3">
			<div className="row">
				<div className="col-auto">
					<Form.Label>
						{isNew &&
							<Form.Control as="input" type={'text'} value={newName} onChange={e => setNewName(e.target.value)} className="me-auto" placeholder="nazwa ustawienia" />
							|| <>{label && <>{label} [{name}]</> || name}</>
						}
						{(allowDelete && !isNew) && <>
							<Button variant="danger" size="sm" className="mx-2" onClick={() => onDelete()}><FontAwesomeIcon icon={faTrash} /></Button>
						</>}
					</Form.Label>
				</div>

				<div className="col-auto my-auto">
				</div>
			</div>
			
			<Stack direction="horizontal" gap={3}>
				{type == 'checkbox' && <Form.Check type="checkbox" checked={value == '1'} onChange={e => setValue(e.target.checked ? '1' : '0')} />
					|| <Form.Control as={ type == 'textarea' ? 'textarea' : 'input' } type={type || 'text'} value={value} onChange={e => setValue(e.target.value)} className="me-auto" placeholder="wartość ustawienia" />
				}
				{(property?.value != value || isNew) && <>
					<div className="ms-auto"><Button variant="success" size="sm" onClick={e => onSave(value)}><FontAwesomeIcon icon={faCheck} /></Button></div>
					<div className=""><Button variant="danger" size="sm" onClick={() => onCancel()}><FontAwesomeIcon icon={faTimesCircle} /></Button></div>
				</>}
			</Stack>
		</Form.Group>
	</>

}


import React from "react";
import { Container } from "react-bootstrap";
import { TenantInfo } from "../Settings/TenantInfo"


export default () => {
	return <Container>
		<h1>DevManager</h1>

		<TenantInfo />
	</Container>
}
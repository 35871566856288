import axios from "axios"
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications"


export const TenantInfo = () => {
	const [tenant, setTenant] = useState({});

	useEffect(() => {
		// get tenant info
		axios.get("/tenant")
			.then(response => {
				setTenant(response.data);
			})
			.catch(() => NotificationManager.error("Nie udało się pobrać danych", "Błąd", 3000))
	}, [])

	return <div>
		<h2>Informacje o dzierżawie</h2>
		Identyfikator dzierżawy: {tenant?.id}<br />
		Nazwa dzierżawy: {tenant?.name}<br />
		Licencja ważna do: {tenant?.license_valid_until || 'bezterminowo'}<br />
		Liczba licencjonowanych urządzeń: {tenant?.license_devices_limit || 'bez ograniczeń'}<br />
	</div>

}
import { configureStore, createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
    name: "app",
    initialState: {
        jwt: null,
		user: null,

    },
    reducers: {
        setJwt: (state, action) => {
            state.jwt = action.payload;
        },

		logoutUser: (state, action) => {
			state.jwt = null;
			state.user = null;
		},

		setUser: (state, action) => {
			state.user = action.payload;
		},

		setAvailabilityCount: (state, action) => {
			state.availabilityCount = action.payload;
		},

		setAvailabilityUnackCount: (state, action) => {
			state.availabilityUnackCount = action.payload;
		},
		
		refresh: (state, action) => {
			state.refresh = !state?.refresh || false;
		}
		


    }
})

export const { setJwt, logoutUser, setUser, setAvailabilityCount, setAvailabilityUnackCount, refresh } = appSlice.actions

export default appSlice.reducer
import { Container, Row, Col, Navbar, Nav, Button } from "react-bootstrap"
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import store from "./redux/store"

import { default as DevicesList } from "./Devices/List"
import { default as UserAuth } from "./User/Auth"
import { default as Home } from "./Home"
import { default as Settings } from "./Settings"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import { logoutUser, refresh, setJwt, setUser } from "./redux/app"

import "react-notifications/lib/notifications.css"
import {
	NotificationContainer,
	NotificationManager,
} from "react-notifications"
import UserList from "./User/List"
import { useEffect, useState } from "react"
import { default as AvailabilityDashboard } from "./Availability/Dashboard"
import { Badge } from "react-bootstrap"
import useRefresh from "./Common/useRefresh"

axios.defaults.baseURL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/api"
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.patch["Content-Type"] = "application/merge-patch+json"

function App() {
	const [availabilityCount, setAvailabilityCount] = useState(0)
	const [availabilityUnackCount, setAvailabilityUnackCount] = useState(0)
	useRefresh();
	const store = useSelector((store) => store.app)
	const dispatch = useDispatch()


	const handleLogout = () => {
		localStorage.removeItem("jwt")
		dispatch(logoutUser())
	}

	useEffect(() => {
		axios.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				if (error.response.status == 401) {
					if (store?.jwt)
						NotificationManager.error("Token autoryzacyjny wygasł!", "Autoryzacja", 3000)
					
					handleLogout()
				}
				return Promise.reject(error)
			}
		)

		if(localStorage.getItem("jwt"))
		{
			dispatch(setJwt(localStorage.getItem("jwt")))
		}
	}, [])


	useEffect(() => {
		dispatch(refresh());

		const interval = setInterval(() => {
			dispatch(refresh());
		}, 10000);

		return () => clearInterval(interval);
	}, [store?.user])

	return (
		<div>
			<BrowserRouter>
				{store?.user && <>
					<Navbar bg="light" expand="lg">
						<Container fluid>
							<Navbar.Brand><Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>DevManager</Link></Navbar.Brand>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="me-auto">
									{store?.user?.roles.includes('ROLE_DEVICES') &&
										<Link to="/devices" className="nav-link">
											Urządzenia
										</Link>
									}
									{store?.user?.roles.includes('ROLE_AVAILABILITY') &&
										<Link to="/availability?only_unresolved=true" className="nav-link">
											Awarie
											{store?.availabilityCount + store?.availabilityUnackCount > 0 && <Badge className="mx-2" bg="danger">{store?.availabilityUnackCount} / {store?.availabilityCount}</Badge>}
										</Link>
									}
									{store?.user?.roles.includes('ROLE_SETTINGS') &&
										<Link to="/settings" className="nav-link">
											Ustawienia
										</Link>
									}
									{store?.user?.roles.includes('ROLE_USERS') &&
										<Link to="/users" className="nav-link">
											Użytkownicy
										</Link>
									}
									{store?.user && <Button variant="danger" onClick={handleLogout}>Wyloguj</Button>}
								</Nav>
							</Navbar.Collapse>
						</Container>
					</Navbar>
					<Routes>
						{/*						<Route path="/screenView" element={<ScreenView />} />*/}
						<Route path="/devices" element={<DevicesList />} />
						<Route path="/availability" element={<AvailabilityDashboard />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/users" element={<UserList />} />
						<Route path="/" element={<Home />} />
					</Routes>
				</> || <UserAuth />}
				<NotificationContainer />
			</BrowserRouter>
		</div>
	)
}

export default App
